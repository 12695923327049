import React, {useState} from 'react';
import {DialogClose, DialogContent} from '../../Dialog';
import Spacer from '../../Spacer';
import {deleteData} from '../../../services/deleteDataOrchestratorService';
import {showToast} from '../../CustomToast/CustomToast';

enum InactiveState {
  Initial,
  Final,
}

function InitialContent({handleState}: { handleState: (state: InactiveState) => void }) {
  return (
    <DialogContent
      title={'Are you sure you want to request to delete your data?'}
      description={
        'Doing this will begin the process to delete your data. You will still have two months to continue to access your account info. After that time, your data will be fully deleted.'
      }
      showCloseButton={true}
    >
      <Spacer height={32}/>
      <button className="button--primary--next" onClick={() => handleState(InactiveState.Final)}>
        Delete My Data
      </button>
    </DialogContent>
  );
}

function FinalContent({handleState, handleOpen}: {
  handleState: (state: InactiveState) => void,
  handleOpen: any
}) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleDeleteData(event: any) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await deleteData();
      showToast(
        '',
        'Your deletion request was submitted.',
        `${process.env.PUBLIC_URL}/assets/images/Checkmark.svg`,
        '#047B41',
        3000
      );
    } catch (error) {
      showToast(
        '',
        'Something wen’t wrong. Try again later.',
        `${process.env.PUBLIC_URL}/assets/images/warning.svg`,
        '#D31700',
        3000
      );
    } finally {
      setIsLoading(false);
      handleState(InactiveState.Initial);
      handleOpen(false);
    }
  }

  return (
    <DialogContent
      title={'Final Confirmation'}
      description={'Do you want to request for your user data to be deleted?'}
    >
      <Spacer height={32}/>
      <DialogClose asChild={true}>
        <div className="l-flex">
          <button className="button--ghost" onClick={() => handleState(InactiveState.Initial)}>
            Cancel
          </button>
          <button className="button--danger" onClick={handleDeleteData}>
            {isLoading ?
              <div className="l-flex-center" style={{height: '22px'}}>
                <img className="spinner button-spinner" src={'/assets/images/spinner-white.svg'} alt="processing"/>
              </div>
              : 'Delete Data'}
          </button>
        </div>
      </DialogClose>
    </DialogContent>
  );
}

export default function InactiveSubscriptionContent({handleOpen}: { handleOpen: any }) {
  const [state, setState] = useState<InactiveState>(InactiveState.Initial);

  function handleState(state: InactiveState) {
    setState(state);
  }

  switch (state) {
    case InactiveState.Initial:
      return <InitialContent handleState={handleState}/>;
    case InactiveState.Final:
      return <FinalContent handleState={handleState} handleOpen={handleOpen}/>;
    default:
      return null;
  }
}
