import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../PaymentForm';

export const stripePromise = loadStripe(`${window.__RUNTIME_CONFIG__.REACT_APP_STRIPE_PK}`);

type PaymentFormWrapperProps = {
    status: 'error' | 'loading' | 'success';
    clientSecret: string;
    setIsCardSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
    calledFrom: string;
    isPasswordSubmitted: boolean;
};

function PaymentFormWrapper({ status, clientSecret, setIsCardSubmitted, calledFrom, isPasswordSubmitted }: PaymentFormWrapperProps) {
    return status === 'success' ?
        <Elements stripe={stripePromise} options={{clientSecret}}>
            <PaymentForm clientSecret={clientSecret} setIsCardSubmitted={setIsCardSubmitted} calledFrom={calledFrom}
                         isPasswordSubmitted={isPasswordSubmitted}/>
        </Elements>
        : null;
}

export default PaymentFormWrapper;
