import React, { useEffect, useState } from 'react';
import classes from './PickupInfoV2.module.scss';
import DropdownPickupDropoffLocation from '../../../../../components/DropdownPickupDropoffLocation/DropdownPickupDropoffLocation';
import DropdownPaymentForm from '../../../../../components/DropdownPaymentForm';
import { getCardDetails } from '../../../../../services/customerService';

const PickupInfoV2 = ({ reservation }) => {
  const [isCardAdded, setIsCardAdded] = React.useState<boolean>();
  const [isCardSubmitted, setIsCardSubmitted] = useState(false);

  useEffect(() => {
    (async () => {
      try {
          const response = await getCardDetails();
          setIsCardAdded(!!response.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isCardAdded]);

  if (isCardAdded === undefined) return null;

  return (
    <div>
      <h4 className={classes.heading} style={{ marginTop: '32px' }}>
        Pickup {reservation?.selectedPickUpDate ? 'Scheduled' : 'Pending'}
      </h4>
      {!isCardAdded && (
        <>
          <DropdownPaymentForm setIsCardSubmitted={setIsCardSubmitted} calledFrom="Dashboard" isPasswordSubmitted={false} expanded={false}/>
          <br />
        </>
      )}
      <DropdownPickupDropoffLocation
        reservation={reservation}
        isPickup={true}
        expanded={isCardAdded || isCardSubmitted}
      />
      <br />
    </div>
  );
};

export default PickupInfoV2;
