import React from 'react';
import './amplitude';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Dashboard from './pages/Dashboard/Dashboard';
import AzureUserFlow from './pages/AzureUserFlow/AzureUserFlow';
import ErrorViewer from './pages/ErrorViewer/ErrorViewer';
import ScrollTop from './components/ScrollToTop/ScrollToTop';
import ChargingSupport from './pages/ChargingSupport/ChargingSupport';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import CreditCheckForm from './pages/CreditCheckForm/CreditCheckForm';
import UpdateAddressForm from './pages/UpdateAddressForm/UpdateAddressForm';
import CreditCardConfirmation from './pages/CreditCardConfirmation/CreditCardConfirmation';
import SuccessScreen from './pages/SuccessScreen/SuccessScreen';
import CreatePassword from './pages/CreatePassword/CreatePassword';
import HomeZipCodeError from './pages/HomeZipCodeError/HomeZipCodeError';
import LoginOrchestrator from './pages/Orchestrator/LoginOrchestrator/LoginOrchestrator';
import FlowOrchestrator from './pages/Orchestrator/FlowOrchestrator/FlowOrchestrator';
import EmailOrchestrator from './pages/Orchestrator/EmailOrchestrator/EmailOrchestrator';
import SignUp from './pages/SignUp/SignUp';
import ContinueJourney from './pages/Orchestrator/ContinueJourney/ContinueJourney';
import ChoosePackage from './pages/ChoosePackage/ChoosePackage';
import EmailLogin from './pages/Orchestrator/EmailOrchestrator/EmailLogin';
import ExperianRejection from './pages/ExperianRejection/ExperianRejection';
import PaymentInformation from './pages/PaymentInformation/PaymentInformation';
import EditPaymentMethod from './pages/EditPaymentMethod';
import Success from './pages/Success';
import PackageSelectionFromWidget from './pages/PackageSelectionFromWidget/PackageSelectionFromWidget';
import Invoices from './pages/Invoices';
import AddPaymentMethod from './pages/AddPaymentMethod';
import AccountSettings from './pages/AccountSettings/AccountSettings';
import Maintenance from './pages/Maintenance/Maintenance';
import UberLogin from './pages/UberLogin/UberLogin';
import Under25LA from './pages/Under25LA';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import Faq from './pages/Faq/Faq';
import Approved from './pages/Approved/Approved';
import RetailFlow from './pages/RetailFlow/RetailFlow';
import ExperianCreditFreeze from './pages/ExperianCreditFreeze/ExperianCreditFreeze';

const App = () => {
  return (
    <BrowserRouter>
      <Toaster />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<EmailLogin />} />
        <Route path="/under-25-la" element={<Under25LA />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/zip-code-error" element={<HomeZipCodeError />} />
        <Route path="/flow-orchestrator" element={<FlowOrchestrator />} />
        <Route path="/login-orchestrator" element={<LoginOrchestrator />} />
        <Route path="/email-orchestrator" element={<EmailOrchestrator />} />
        <Route path="/continue-journey" element={<ContinueJourney />} />
        <Route path="/login-email" element={<EmailLogin />} />
        <Route path="/account-package-selection" element={<ChoosePackage />} />
        <Route path="/payments" element={<CreditCardConfirmation />} />
        {JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED) && (
          <Route path="/approved" element={<Approved />} />
        )}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/azure-user-flow" element={<AzureUserFlow />} />
        <Route path="/experian-rejection" element={<ExperianRejection />} />
        <Route path="/experian-credit-freeze" element={<ExperianCreditFreeze />} />
        <Route path="/settings" element={<AccountSettings />} />
        <Route path="/error/:type" element={<ErrorViewer />} />
        <Route path="/login-uber" element={<UberLogin />} />
        {JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_RETAIL_FLOW_FLAG) && (
          <Route path="/retail" element={<RetailFlow />} />
        )}
        <Route path="/support/:type" element={<ChargingSupport />} />
        <Route path="*" element={<ErrorViewer />} />
        <Route path="/payment-information" element={<PaymentInformation />} />
        <Route path="/add-payment-method" element={<AddPaymentMethod />} />
        <Route path="/success/:type" element={<Success />} />
        <Route path="/edit-payment-method" element={<EditPaymentMethod />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/invoices/:invoiceId" element={<Invoices />} />
        <Route path="/package-selection" element={<PackageSelectionFromWidget />} />
        <Route path="/success" element={<SuccessScreen />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/update-address/:type" element={<UpdateAddressForm />} />
        <Route path="/additional-profile" element={<CreditCheckForm />} />
        <Route path="/credit-card-confirmation" element={<CreditCardConfirmation />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
