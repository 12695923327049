import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import NavWebHeader from '../../components/NavWebHeader/NavWebHeader';
import classes from './ExperianCreditFreeze.module.scss';
import {
  trackCreditFreezeContactExperianButtonClick,
  trackCreditFreezePageLoad,
} from '../../services/analyticsService';

const ExperianCreditFreeze = () => {
  const onClick = () => {
    window.location.href = 'tel:855-819-7973';
    trackCreditFreezeContactExperianButtonClick();
  };
  useEffect(() => {
    trackCreditFreezePageLoad();
  }, []);

  return (
    <>
      <div className="l-height-bg">
        <NavWebHeader title="Verification" shouldNav={false} />
        <div className="l-border-radius-bg">
          <div className="l-center">
            <section className={classes.content}>
              <div className={classes['content-size']}>
                <img className={classes.image} src={'/assets/images/Rejection.svg'} alt="Ryze Rejection Logo" />
                <h5 className={classes.header}>Your credit report is frozen.</h5>
                <div className={classes.text}>
                  <p>
                    We appreciate your interest in the Ford Drive Rideshare Program. In order to proceed with your
                    application, you will need to contact Experian to unfreeze your credit report. Once completed,
                    return to our website in 24 hours to re-apply.
                  </p>
                  <p>
                    If you would like to review a copy of your credit profile, you may contact Experian directly at:
                  </p>
                  <p className={classes.bolded}>
                    Experian MCE (My Customer Experience)
                    <br />
                    PO Box 74 Allen, TX 75013
                    <br />
                    Phone (855) 819-7973
                    <br />
                    Fax (972) 390-4987
                  </p>
                </div>
                <button className={classes['experian-contact-btn']} onClick={onClick}>
                  Contact Experian
                </button>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ExperianCreditFreeze;
