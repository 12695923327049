import Dialog from '../../Dialog';
import InactiveSubscriptionContent from './InactiveSubscriptionContent';
import ActiveSubscriptionContent from './ActiveSubscriptionContent';

interface ManageSubscriptionProps {
  status: 'active' | 'inactive';
  open: boolean;
  setOpen: any;
}

function DeleteUserDataModal({status, open, setOpen}: ManageSubscriptionProps) {
  const Content = status === 'active' ? <ActiveSubscriptionContent/> :
    <InactiveSubscriptionContent handleOpen={setOpen}/>;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {Content}
    </Dialog>
  );
}

export default DeleteUserDataModal;
