import Dropdown from '../Dropdown';
import PasswordForm from '../PasswordForm';

function DropdownPasswordForm({ expanded, isCardSubmitted, setIsPasswordSubmitted}) {
  return (
    <Dropdown title="Create Password" key={isCardSubmitted}>
      <PasswordForm expanded={expanded}
                    isCardSubmitted={isCardSubmitted}
                    setIsPasswordSubmitted = {setIsPasswordSubmitted}
      />
    </Dropdown>
  );
}

export default DropdownPasswordForm;
