import React, {RefAttributes} from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import styles from './Dialog.module.scss';

function XIcon({width = 32, height = 32, fill = '#5A5C62'}) {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(4, 4) scale(1.5)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99976 9.46635L12.8326 14.299C13.0995 14.5659 13.5323 14.5659 13.7992 14.299C14.0661 14.032 14.0661 13.5993 13.7991 13.3324L8.96636 8.49979L13.7996 3.66678C14.0665 3.39987 14.0665 2.96712 13.7996 2.7002C13.5327 2.43328 13.0999 2.43327 12.833 2.70018L7.99976 7.53322L3.16652 2.70018C2.8996 2.43327 2.46684 2.43328 2.19993 2.7002C1.93302 2.96712 1.93303 3.39987 2.19995 3.66678L7.03315 8.49979L2.20037 13.3324C1.93345 13.5993 1.93344 14.032 2.20035 14.299C2.46725 14.5659 2.90001 14.5659 3.16693 14.299L7.99976 9.46635Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

interface DialogContentProps extends DialogPrimitive.DialogContentProps {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  showCloseButton?: boolean;
}

export const DialogContent: React.ForwardRefExoticComponent<DialogContentProps & RefAttributes<HTMLDivElement>> =
  React.forwardRef(({children, title, description, showCloseButton, ...props}, forwardedRef) => (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className={styles.DialogOverlay}/>
      <DialogPrimitive.Content {...props} ref={forwardedRef} className={styles.DialogContent}>
        {showCloseButton && (
          <DialogClose asChild={true}>
            <button className={styles.IconButton} aria-label={'close'} tabIndex={0}>
              <XIcon/>
            </button>
          </DialogClose>
        )}
        {title && <DialogPrimitive.Title className={styles.DialogTitle}>{title}</DialogPrimitive.Title>}
        {description && (
          <DialogPrimitive.Description className={styles.DialogDescription}>{description}</DialogPrimitive.Description>
        )}
        {children}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  ));

export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;
export default DialogPrimitive.Root;
