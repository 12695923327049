import { ComponentPropsWithoutRef, createContext, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './Dropdown.module.scss';

interface DropdownProps extends ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
  title: string;
  expanded?: boolean;
}

export const ExpandContext = createContext(null);

function Dropdown({ children, title, expanded = false, ...props }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(expanded);
  const contentRef = useRef(null);

   useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.height = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  const className = `${styles.dropdown} ${!isOpen ? styles.closed : ''}`;

  return (
    <div {...props} className={className}>
      <div className={styles.dropdownTitle} onClick={() => setIsOpen(!isOpen)}>
        {title}
        <Arrow isOpen={isOpen} />
      </div>
      <div className={styles.dropdownContent} ref={contentRef}>
        <ExpandContext.Provider value={setIsOpen}>{children}</ExpandContext.Provider>
      </div>
    </div>
  );
}

function Arrow({ isOpen }) {
  return (
      <svg
          className={`${styles.arrow} ${isOpen ? styles.up : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
      >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.66663 10.4113L3.23602 12L7.99996 7.17742L12.7639 12L14.3333 10.4113L7.99996 4L1.66663 10.4113Z"
            fill="#026AD2"
        />
      </svg>
  );
}

export default Dropdown;
