import Dropdown from '../Dropdown';
import PaymentFormWrapper from '../PaymentsFormWrapper';
import { useCreatePaymentIntent } from '../../hooks/useCreatePaymentIntent';

function DropdownPaymentForm({ setIsCardSubmitted, calledFrom, isPasswordSubmitted, expanded}) {
  const [createPaymentIntentStatus, clientSecret] = useCreatePaymentIntent();

  return (
    <Dropdown title="Payment Card Info" expanded={expanded}>
      <PaymentFormWrapper
        status={createPaymentIntentStatus}
        clientSecret={clientSecret}
        setIsCardSubmitted={setIsCardSubmitted}
        calledFrom={calledFrom}
        isPasswordSubmitted = {isPasswordSubmitted}
      />
    </Dropdown>
  );
}

export default DropdownPaymentForm;
