import {useAtom} from 'jotai';
import {toast} from 'react-hot-toast';
import {ReservationStatus} from '../../models/ReservationStatus';
import {newPricingSelectedAtom} from '../PricingTierSwitchConfirmationModal/PricingTierSwitchConfirmationModal';

type ToasterContentProps = {
  title: string;
  description: string;
  borderColor: string;
  image: string;
  toastId: string;
};

export function CustomToast({title, description, borderColor, image, toastId}: ToasterContentProps) {
  const [newPricingSelected, setNewPricingSelected] = useAtom(newPricingSelectedAtom);
  const activeStates = [ReservationStatus.ACTIVE, ReservationStatus.PENDING_RETURN];

  function handleDismiss() {
    setNewPricingSelected({...newPricingSelected, wasClosed: true});
    toast.remove(toastId);
  }

  const content =
    newPricingSelected.wasClosed && activeStates.includes(newPricingSelected?.currentReservationStatus) ?
      null
      : <div className="toaster">
        <div className="toaster__content" style={{borderLeft: `2px solid ${borderColor}`}}>
          <img src={image} alt="toaster image type"/>
          <div className="toaster__text">
            <div className="toaster__title">{title}</div>
            <div className="toaster__description">{description}</div>
          </div>
          <img
            data-testid="dismiss"
            onClick={handleDismiss}
            src={`${process.env.PUBLIC_URL}/assets/images/x.svg`}
            style={{cursor: 'pointer'}}
            alt="close icon"
          />
        </div>
      </div>;

  return content;
}

export function showToast(title: string, description: string, image: string, borderColor: string, duration = Infinity) {
  toast.custom(
    (t) => (
      <CustomToast title={title} description={description} image={image} borderColor={borderColor} toastId={t.id}/>
    ),
    {
      duration,
      style: {
        marginTop: '20px',
      },
      id: 'copy',
    }
  );
}

export function hideToasts(id: string) {
  toast.remove(id);
}
