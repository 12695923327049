import {DialogClose, DialogContent} from '../../Dialog';
import Spacer from '../../Spacer';

export default function ActiveSubscriptionContent() {
  return (
    <DialogContent
      title={'It looks like your reservation is still active.'}
      description={
        'Speak with a customer service agent now about how to cancel your reservation and return your vehicle before continuing.'
      }
      showCloseButton={true}
    >
      <Spacer height={32}/>
      <DialogClose asChild={true}>
        <a href="tel:800-805-5462" className="button--primary--next">
          800-805-5462
        </a>
      </DialogClose>
    </DialogContent>
  );
}
