import { saveSecondaryProfileData } from '../../services/noAuthFlowService';
import { runCreditVerification } from '../../services/creditService';
import { updateJourney } from '../../services/journeyService';
import { PageKey, PageURL } from '../../models/PageKey';
import { useNavigate } from 'react-router-dom';
import { createRequest, SecondaryProfileFormValues } from './utils';
import { ErrorType } from '../ErrorViewer/ErrorViewer';
import { useEffect, useState } from 'react';
import { RedirectTo } from '../UpdateAddressForm/UpdateAddressForm';
import {
  trackApplicationApprovalPageDuration,
  trackApplicationApprovalPageLoad,
  trackApplicationApprovalSubmitButtonClick,
} from '../../services/analyticsService';
import { usePageDuration } from '../../hooks/usePageDuration';
import { Journey } from '../../models/Journey';
import { EmailType, sendEmail } from '../../services/emailService';
import { is25OrOlder } from '../../utils/dateUtil';
import { createCustomerForBff } from '../../services/stripeService';
import { addWaitListReservation } from '../../services/reservationService';

export default function useCreditCheckForm(shouldShowContent: boolean, journey: Journey) {
  const navigate = useNavigate();
  const [consent, setConsent] = useState('');
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [allFieldsDirty, setAllFieldsDirty] = useState(false);
  const disableEditing = processing || success;
  const defaultValues = {
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    city: '',
    consents: false,
    driversLicenseNumber: '',
    homeAddress: '',
    state: '',
    zipCode: '',
  };

  useEffect(() => {
    if (!shouldShowContent) return;
    trackApplicationApprovalPageLoad();
  }, [shouldShowContent]);

  usePageDuration(trackApplicationApprovalPageDuration, shouldShowContent);

  useEffect(() => {
    if (allFieldsDirty === enableSubmit) return;
    setEnableSubmit(allFieldsDirty);
  }, [allFieldsDirty]);

  const onAnimationEnd = () => {
    if (window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED === 'true') {
      navigate(PageURL.APPROVED);
    } else {
      navigate(PageURL.PAYMENT);
    }
  };

  function onConsentEventHandler(llId: any) {
    setConsent(llId);
  }

  function enableButton() {
    setProcessing(false);
    setEnableSubmit(true);
  }

  // Helper function for error logging and enabling button
  function logErrorAndEnableButton(message: string) {
    console.error(message);
    enableButton();
  }

  // Function to check consent
  async function checkConsent(consent: string) {
    if (consent.length < 1) {
      logErrorAndEnableButton('No consent');
      return false;
    }
    return true;
  }

  // Function to handle secondary profile response
  async function handleSecondaryProfile(data: SecondaryProfileFormValues, consent: string) {
    const secondaryProfileResp = await saveSecondaryProfileData(createRequest(data, consent));
    if (secondaryProfileResp.status !== 200) {
      logErrorAndEnableButton('Error saving secondary profile data');
      return false;
    }
    return true;
  }

  // Function to handle market and age
  async function handleMarketAndAge(data: SecondaryProfileFormValues) {
    const isMarketLa = journey.metadata && journey.metadata.market === 'LA';
    if (isMarketLa && !is25OrOlder(data.birthYear, data.birthMonth, data.birthDay)) {
      console.error('Error user under 25 in LA');
      await sendEmail({ emailType: EmailType.UNDER_25_LA });
      navigate('/under-25-la');
      return false;
    }
    return true;
  }

  // Function to handle credit verification
  async function handleCreditVerification() {
    const creditVerificationResp = await runCreditVerification();
    if (creditVerificationResp.status !== 200) {
      logErrorAndEnableButton('Error running credit verification');
      return false;
    }
    if (!creditVerificationResp.data.verified) {
      logErrorAndEnableButton('Experian credit data is unverified');
      navigate('/experian-rejection');
      return false;
    }
    return true;
  }

  async function createStripeCustomer() {
    if (JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED)) {
      const { status } = await createCustomerForBff(journey.metadata.market);
      if (status !== 200) {
        logErrorAndEnableButton('Error creating stripe customer');
        return false;
      }
    }
    return true;
  }

  async function createReservation() {
    if (JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED)) {
      const createReservationResponse = await addWaitListReservation(
        journey.metadata.market,
        journey.metadata.packageId
      );
      const updatedJourney = await updateJourney({
        externalId: createReservationResponse.data?.id,
        pageKey: PageKey.SIGNUP_SUCCESS,
      });
      if (createReservationResponse.status !== 200) {
        logErrorAndEnableButton('Create reservation response was not 200');
        return false;
      }
      if (updatedJourney.status !== 200) {
        logErrorAndEnableButton('Error updating journey');
        return false;
      }
    }
    return true;
  }

  // Function to handle journey update
  async function handleJourneyUpdate(verified: boolean) {
    const updatedJourney = await updateJourney({
      pageKey: JSON.parse(window.__RUNTIME_CONFIG__.REACT_APP_CC_REMOVED) ? PageKey.SIGNUP_SUCCESS : PageKey.PAYMENT,
      metadata: {
        experianVerified: verified,
      },
    });
    if (updatedJourney.status !== 200) {
      logErrorAndEnableButton('Error updating journey');
      return false;
    }
    return true;
  }

  // Function to handle errors
  function handleError(e: any) {
    console.error(e);
    if (e.config?.url.includes('v1/email')) {
      navigate('/under-25-la');
    } else if (e.data?.errorCode) {
      const errorNavigationMap = {
        VR402: () => navigate('/error/' + ErrorType.EXPERIAN_SSN_ERROR),
        VR404: () => navigate('/error/' + ErrorType.EXPERIAN_SSN_ERROR),
        VR403: () => navigate('/update-address/' + RedirectTo.EXPERIAN),
        VR405: () => navigate('/update-address/' + RedirectTo.EXPERIAN),
        VR406: () => navigate('/experian-rejection'),
        VR407: () => navigate('/experian-rejection'),
        VR408: () => navigate('/experian-rejection'),
        VR409: () => navigate('/experian-rejection'),
        VR412: () => navigate('/experian-credit-freeze'),
      };
      const navigateFunc = errorNavigationMap[e.data.errorCode];
      if (navigateFunc) navigateFunc();
      else navigate('/error/' + ErrorType.EXPERIAN_ERROR);
    } else {
      navigate('/error/' + ErrorType.EXPERIAN_ERROR);
    }
    enableButton();
  }

  async function onSubmit(data: SecondaryProfileFormValues) {
    try {
      trackApplicationApprovalSubmitButtonClick();
      setProcessing(true);
      setEnableSubmit(false);
      if (
        (await checkConsent(consent)) &&
        (await handleSecondaryProfile(data, consent)) &&
        (await handleMarketAndAge(data)) &&
        (await handleCreditVerification()) &&
        (await handleJourneyUpdate(true)) &&
        (await createStripeCustomer()) &&
        (await createReservation())
      ) {
        setSuccess(true);
      }
    } catch (e) {
      handleError(e);
    }
  }

  return {
    allFieldsDirty,
    defaultValues,
    disableEditing,
    enableSubmit,
    processing,
    success,
    onAnimationEnd,
    onConsentEventHandler,
    onSubmit,
    setAllFieldsDirty,
  };
}
